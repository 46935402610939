import React from 'react';
import { useParams } from 'react-router-dom';

const PatientProfile = () => {
  const { id } = useParams();

  return (
    <div>
      <h1>Perfil do Paciente</h1>
      <p>ID do Paciente: {id}</p>
      {/* Exiba outras informações do paciente aqui */}
    </div>
  );
};

export default PatientProfile;
