const formatName = (fullName) => {
  const nameParts = fullName.split(' ').map(part => 
    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );
  if (nameParts.length > 1) {
    return `${nameParts[0]} ${nameParts[1]} ${nameParts[nameParts.length - 1]}`;
  }
 
  return fullName; // Caso haja apenas um nome, retorna ele mesmo
};


export default formatName