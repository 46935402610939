import React, { useState } from 'react';
import './css/administration.css';
import './css/administrationMob.css';
import Header from '../../components/header/Header';
import QueeCall from '../../components/quee/queeAdm/QueeCall';
import QueeManagerBtn from '../../components/quee/queeAdm/QueeManagerBtn';
import QueeContainer from '../../components/quee/queeContainer/QueeContainer';
// import InServiceClearBtn from '../../components/quee/queeAdm/InServiceClearBtn';
import RegisterRedirectBtn from '../../components/cadastro/RegisterRedirectBtn';
import QueeClearBtn from '../../components/quee/queeAdm/QueeClearBtn';


export default function Administration() {
  const [noUperCover] = useState('no-uper-cover')
  const [noRemoverBtn] = useState('no-remover-btn')
  const [noRoundBtn] = useState('no-round-btn')
  
 
  const handleCallNext = async () => {
    try {
      const response = await fetch('http://localhost:3001/quee/next', {
        method: 'DELETE',
      });
      if (response.ok) {
        alert('Próximo paciente foi chamado com sucesso!');
      
        // Atualize a lista de pessoas após chamar o próximo paciente com sucesso
        // Você pode optar por recarregar a página ou atualizar os dados diretamente, dependendo da sua preferência
      } else {
        throw new Error('Erro ao chamar próximo paciente');
      }
    } catch (error) {
      console.error('Erro ao chamar próximo paciente:', error);
      alert('Erro ao chamar próximo paciente. Por favor, tente novamente.');
    }
  };

  // const handleQuee = async () => {
  //   try {
  //     const response = await fetch('http://localhost:3000/quee/open-close', {
  //       method: 'PATCH',
  //     });
  //     if (response.ok) {
  //       alert('A fila foi aberta!');
  //       console.log(response);
  //       // Atualize a lista de pessoas após chamar o próximo paciente com sucesso
  //       // Você pode optar por recarregar a página ou atualizar os dados diretamente, dependendo da sua preferência
  //     } else {
  //       throw new Error('Erro ao abrir a fila.');
  //     }
  //   } catch (error) {
  //     console.error('Erro ao abrir a fila:', error);
  //     alert('Erro ao abrir a fila. Por favor, tente novamente.');
  //   }
  // };


  return (
    
    <div className='adm'>
      <Header />
      <div className='control-painel'>
        <div className='painel-de-controle-div'>
        <span className='painel-de-controle'>Painel de Controle</span>
        </div>
      <div className='adm-btns-container'>
      <QueeManagerBtn />
      <QueeCall onCallNext={handleCallNext} />
      {/* <InServiceClearBtn /> */}
      <QueeClearBtn />
      <RegisterRedirectBtn />
      
      </div>
      </div>
      <QueeContainer noUperCover={noUperCover} noRemoverBtn={noRemoverBtn} noRoundBtn={noRoundBtn} />
    </div>
    
  );
}
