import React, { useState } from 'react';
import './css/queeEntryMob.css'
import './css/queeEntry.css'

function QueeEntry({ onAddPerson }) {
  const [documento, setDocumento] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Verifique se o documento tem no máximo 11 caracteres
    if (documento.length <= 11 && documento.length > 0 ) {
      // Chame a função de callback onAddPerson com o documento
      onAddPerson({ doc: documento });
      // Limpe o campo de entrada após o envio
      setDocumento('');
    } else {
      alert('O documento deve ter no máximo 11 caracteres');
    }
  };

  return (
    <form 
    className='quee-entry-form'
    onSubmit={handleSubmit}>
      <input
      className='input-doc'
        type="text"
        value={documento}
        onChange={(e) => setDocumento(e.target.value)}
        placeholder="Apenas números: RG ou CPF ou COD"
        maxLength={11} // Limite máximo de 11 caracteres
      />
      <button 
      className='quee-entry-btn'
      type="submit">Entrar na Fila</button>
    </form>
  );
}

export default QueeEntry;
