// QueeCall.js
import React from 'react';

export default function QueeCall({ onCallNext }) {

  const handleCallNext = () => {
    const confirmCall = window.confirm('Deseja chamar o próximo paciente da fila?');
    if (confirmCall) {
      onCallNext(); // Chama a função para chamar o próximo paciente
    }
  };

  return (
   
      <button className= 'adm-btns'onClick={handleCallNext}>Chamar Paciente</button>
   
   
  );
}
